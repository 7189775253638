import request from '@/utils/request'

//新增鱼类类型信息
export function AddFishType(data) {
    return request({
        url: '/admin/fishtype/addfishspec',
        method: 'post',
        data
    })
}

//获取类型列表
export function getFishTypeList(page) {
    return request({
        url: '/typelist?page=' +page,
        method: 'get',
    })
}

//删除鱼类类型
export function DelFishType(id) {
    return request({
        url: '/admin/fishtype/Delspec/'+id,
        method: 'delete',
        
    })
}