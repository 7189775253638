<template>
  <vxe-toolbar ref="toolbarRef" print export>
    <template #buttons>
      <el-row :gutter="20">
        <el-col :span="3">
          <vxe-button icon="vxe-icon-add" size="mini" status="primary" content="新增"
            @click="centerDialogVisible = true"></vxe-button>
        </el-col>

        <!--  <vxe-button content="打印勾选行" @click="printSelectEvent"></vxe-button> -->

      </el-row>
    </template>
  </vxe-toolbar>
  <div style="height: calc(100vh - 210px)">
  <vxe-table :data="tableData" ref="tableRef" size="small" :row-config="{isCurrent: true, isHover: true}" :print-config="{}" height="100%">
    <!-- 表格列配置 -->
    <vxe-column type="seq" width="50"></vxe-column>
    <vxe-column field="name" title="鱼类"></vxe-column>
    <vxe-column field="spec" title="单位"></vxe-column>
    <vxe-column field="precommission" title="提成金额(¥)"></vxe-column>
    <vxe-column title="操作">

      <template v-slot="scope">
       
        <el-popconfirm title="操作不可逆!,并且会影响历史已输入的数据" @confirm="delFishType(scope.row.id)">
          <template #reference>
            <el-button link type="danger" size="mini">删除</el-button>
          </template>
        </el-popconfirm>
      </template>

    
    
    
    </vxe-column>
  </vxe-table>
</div>
  <vxe-pager background v-model:current-page="pageVO2.currentPage" v-model:page-size="pageVO2.pageSize" @page-change="pagechangeHandle()"
    :total="pageVO2.total"
    :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'FullJump', 'Total']">
  </vxe-pager>

  <!--这里是新增弹窗-->
  <el-dialog v-model="centerDialogVisible" v-if="centerDialogVisible" title="新增数据" width="25%" :destroy-on-close="true" :close-on-click-modal="false" 
    @close="closeLogDialog">
    <el-form label-width="80px" :model="formLabelAlign" style="max-width: 460px" size="small" label-position="right" :rules="rules">
      <el-row>
        <el-col :span="8">
          <el-form-item label="鱼类名称" prop="name">
            <el-input style="width: 200px;" v-model="formLabelAlign.name" size="small"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
       <!--    <el-form-item label="提成比例">
            <el-input-number v-model="formLabelAlign.num" :precision="2" :max="999999" size="small" />
          </el-form-item> -->

          <el-form-item label="提成金额" prop="precommission" >
      <el-input v-model="formLabelAlign.precommission" style="width: 200px;">
        <template #append>¥</template>
      </el-input>
    </el-form-item>
  </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeLogDialog()" size="small">取消</el-button>
        <el-button type="primary" @click="submitData()" size="small">
          提交
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ElMessage } from 'element-plus'
import { ref, reactive, onBeforeMount } from 'vue'
import { useStore } from "vuex";
import {AddFishType,getFishTypeList ,DelFishType} from '@/api/fishtype.js'
const toolbarRef = ref()
const tableRef = ref()
const tableData = ref([])
const store = useStore();
//新增数据变量
const formLabelAlign = reactive({
  name: null,
  precommission: 0.00,
})
//弹窗控制变量
const centerDialogVisible = ref(false)


//分页变量
const pageVO2 = reactive({
  currentPage: 1,
  pageSize: 20,
  total: 8
})

const delFishType = (id)=>{
  DelFishType(id).then(res=>{
    if (res.code !== 200) {
      ElMessage.error(res.msg)
    } else {
      SeachDataHandle()
      ElMessage({
        message: res.msg,
        type: 'success',
      })
    }
})
}

//提交函数
const submitData = () => {

  console.log(formLabelAlign);
  AddFishType(formLabelAlign).then(res => {
    if (res.code !== 200) {
      ElMessage.error(res.msg)
    } else {
      SeachDataHandle()
      ElMessage({
        message: res.msg,
        type: 'success',
      })
      store.dispatch('user/getFishType')
      closeLogDialog()
    }
  })

}
//查询函数
const SeachDataHandle = () => {
  let page = pageVO2.currentPage
  getFishTypeList(page).then(res => {
    tableData.value = res.data.data
    pageVO2.currentPage = res.data.current_page
    pageVO2.total = res.data.total
  }) 
}
const closeLogDialog = () => {
  centerDialogVisible.value = false
  formLabelAlign.name = null
  formLabelAlign.precommission = 0

}
//分页变化
const pagechangeHandle=(type, currentPage)=>{
  SeachDataHandle(currentPage);
}
onBeforeMount(() => {

 /*  getFishTypeList().then(res => {
    typeinfos.value = res.data
  }) */

  getFishTypeList(1).then(res => {
    tableData.value = res.data.data
    pageVO2.currentPage = res.data.current_page
    pageVO2.total = res.data.total
  })
})
const rules = {
  percentage: [
    { required: true, message: '请输入数字' },
    { pattern: /^[1-9]\d*$/, message: '只能输入非负整数', trigger: 'blur' }
  ],
  precommission: [
    { required: true, message: '只能输入不小于0且最多有两位小数的数字' },
    { pattern: /^[0-9]+(.[0-9]{1,2})?$/, message: '只能输入不小于0且最多有两位小数的数字', trigger: 'blur' }
  ],
  Offline: [
    { required: true, message: '请输入数字' },
    { pattern: /^[0-9]+(.[0-9]{1,2})?$/, message: '只能输入不小于0且最多有两位小数的数字', trigger: 'blur' }
  ],
  customprice: [
    { required: true, message: '请输入数字' },
    { pattern: /^-?\d+(?:\.\d{1,2})?$/, message: '只能输入不小于0且最多有两位小数的数字', trigger: 'blur' }
  ],
  name: [
    {required:true, message:'请输入名称'},
  ]
};
</script>

<style scoped></style>